import React, { useState, useEffect } from 'react';
import { getExamInfo, ExamInfo } from '../services/api';
import '../styles/ExamInfoPage.scss';
import ImageModal from './ImageModal';

const floors = [
  'https://oss-img.ciduid.top/static/imgs/floor/floor1.png',
  'https://oss-img.ciduid.top/static/imgs/floor/floor2.png',
  'https://oss-img.ciduid.top/static/imgs/floor/floor3.png',
  'https://oss-img.ciduid.top/static/imgs/floor/floor4.png',
  'https://oss-img.ciduid.top/static/imgs/floor/floor5.png',
];

const zones = [
  [
    'https://oss-img.ciduid.top/static/imgs/exams/11.png',
    'https://oss-img.ciduid.top/static/imgs/exams/12.png',
    'https://oss-img.ciduid.top/static/imgs/exams/13.png',
  ],
  [
    'https://oss-img.ciduid.top/static/imgs/exams/21.png',
    'https://oss-img.ciduid.top/static/imgs/exams/22.png',
    'https://oss-img.ciduid.top/static/imgs/exams/23.png',
  ],
  [
    'https://oss-img.ciduid.top/static/imgs/exams/31.png',
    'https://oss-img.ciduid.top/static/imgs/exams/32.png',
    'https://oss-img.ciduid.top/static/imgs/exams/33.png',
  ],
  [
    'https://oss-img.ciduid.top/static/imgs/exams/41.png',
    'https://oss-img.ciduid.top/static/imgs/exams/42.png',
    'https://oss-img.ciduid.top/static/imgs/exams/43.png',
  ],
  [
    'https://oss-img.ciduid.top/static/imgs/exams/51.png',
    'https://oss-img.ciduid.top/static/imgs/exams/52.png',
    'https://oss-img.ciduid.top/static/imgs/exams/53.png',
  ],
];

const ExamInfoPage: React.FC = () => {
  const [examNumber, setExamNumber] = useState('');
  const [name, setName] = useState('');
  const [examInfo, setExamInfo] = useState<ExamInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [floorImg, setFloorImg] = useState('');
  const [zoneImg, setZoneImg] = useState('');
  const [modalImage, setModalImage] = useState<string | null>(null);

  const extractFloorAndZone = (exam_location: string) => {
    console.log('Extracting floor and zone from:', exam_location);
    const regex = /\d(\d)(\d)\d{2}/;
    const match = exam_location.match(regex);
    if (match) {
      const floor = parseInt(match[2]);
      const zone = parseInt(match[1]);
      console.log('Extracted floor:', floor, 'zone:', zone);
      
      if (floor >= 1 && floor <= 5 && zone >= 1 && zone <= 3) {
        setFloorImg(floors[floor - 1]);
        setZoneImg(zones[floor - 1][zone - 1]);
      } else {
        console.error('Invalid floor or zone number:', floor, zone);
        setFloorImg('');
        setZoneImg('');
      }
    } else {
      console.error('Invalid exam_location format:', exam_location);
      setFloorImg('');
      setZoneImg('');
    }
  };

  useEffect(() => {
    console.log('examInfo changed:', examInfo);
    if (examInfo && examInfo.exam_location) {
      console.log('Calling extractFloorAndZone with:', examInfo.exam_location);
      try {
        extractFloorAndZone(examInfo.exam_location);
      } catch (error) {
        console.error('Error in extractFloorAndZone:', error);
        setFloorImg('');
        setZoneImg('');
      }
    } else {
      console.log('No valid exam_location found');
      setFloorImg('');
      setZoneImg('');
    }
  }, [examInfo]);

  const fetchExamInfo = async () => {
    if (examNumber.trim() === '' || name.trim() === '') {
      setError('请填写完整的准考证号和姓名');
      return;
    }

    try {
      const response = await getExamInfo(examNumber, name);
      console.log('API response:', response);
      setExamInfo(response);
      setError(null);
    } catch (error: any) {
      console.error('获取考生考试信息失败:', error);
      if (error.response && error.response.status === 404) {
        setError('准考证号或姓名错误');
      } else {
        setError('获取考生考试信息失败,请稍后重试');
      }
      setExamInfo(null);
    }
  };

  const handleImageClick = (imageSrc: string) => {
    setModalImage(imageSrc);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };

  const handleReset = () => {
    setExamNumber('');
    setName('');
    setExamInfo(null);
    setError(null);
    setFloorImg('');
    setZoneImg('');
  };

  return (
    <div className="exam-info-page">
      <div className="container">
        <h1 className="title">武科大考场考点查询</h1>
        <div className="input-group">
          <label className="input-label">准考证号:</label>
          <input
            className="input-field"
            value={examNumber}
            onChange={(e) => setExamNumber(e.target.value)}
            placeholder="请输入准考证号"
          />
        </div>
        <div className="input-group">
          <label className="input-label">考生姓名:</label>
          <input
            className="input-field"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="请输入考生姓名"
          />
        </div>
        <button className="query-button" onClick={fetchExamInfo}>
          查询
        </button>
        {error && <p className="error-message">{error}</p>}
        {examInfo && (
          <div className="exam-info">
            <h3>考试信息</h3>
            <div className="info-item">
              <span className="info-label">考试名称:</span>
              <span className="info-value">{examInfo.exam_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">准考证号:</span>
              <span className="info-value">{examInfo.exam_number}</span>
            </div>
            <div className="info-item">
              <span className="info-label">考生姓名:</span>
              <span className="info-value">{examInfo.name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">考试地点:</span>
              <span className="info-value">{examInfo.exam_location}</span>
            </div>
            {examInfo.other_info && typeof examInfo.other_info === 'object' && Object.entries(examInfo.other_info).map(([key, value]) => (
              <div className="info-item" key={key}>
                <span className="info-label">{key}:</span>
                <span className="info-value">{String(value)}</span>
              </div>
            ))}
            {floorImg && (
              <div className="img-container">
                <h3>楼层图</h3>
                <img 
                  src={floorImg} 
                  alt="Floor" 
                  className="floor-image" 
                  onClick={() => handleImageClick(floorImg)}
                />
              </div>
            )}
            {zoneImg && (
              <div className="img-container">
                <h3>区域图</h3>
                <img 
                  src={zoneImg} 
                  alt="Zone" 
                  className="zone-image" 
                  onClick={() => handleImageClick(zoneImg)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {modalImage && (
        <ImageModal imageSrc={modalImage} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default ExamInfoPage;
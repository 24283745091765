import axios from 'axios';

// const BASE_URL = 'http://localhost:3000/api';
const BASE_URL = 'https://examinfoback.saroz.top/api';

export interface ExamInfo {
  id: number;
  name: string;
  exam_number: string;
  exam_location: string;
  other_info: string;
  exam_id: number;
  exam_name: string;
}

export const getExamInfo = async (exam_number: string, name: string): Promise<ExamInfo> => {
  try {
    const response = await axios.get(`${BASE_URL}/candidates`, {
      params: { exam_number, name }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching exam info:', error);
    throw error;
  }
};